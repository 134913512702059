import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { ampli } from '@/plugins/product/ampli';

export const project = {
  mixins: [modal],
  computed: {
    ...mapState({
      projects: (state) => state.manageProjects.projectList
    }),
    ...mapGetters({
      isFreePlan: 'account/isFreePlan',
      existingNames: 'manageProjects/existingNames'
    }),
    isRestrictedFromAddingProject() {
      return this.projects?.permanent?.length && this.isFreePlan;
    }
  },
  methods: {
    ...mapActions({
      createProject: 'manageProjects/createProject'
    }),
    handleCreateProject($amp_source) {
      ampli.createProjectClicked({  createProjectButtonSource: $amp_source });

      if (!this.isFreePlan) {
        return this.showModal(modalsId.CREATE_PROJECT);
      }

      if (this.isRestrictedFromAddingProject) {
        ampli.upgradeStreamlineWindowViewed({ upgradeWindowSource: 'createProject' });

        return this.showModal(modalsId.UPGRADE_PLAN);
      }

      // free users can create only 2.0 projects
      this.showModal(modalsId.SL_RENAME_MODAL, {
        text: '',
        title: this.$t('Web.Project.ProjectName'),
        validationRules: {
          'sl_not_includes': {
            values: this.existingNames,
            message: this.$t('Web.Error.AlreadyExist', {
              1: this.$t('Web.Error.Names.Project')
            })
          }
        },
        submitText: this.$t('Web.Modals.BtnCreate'),
        placeholder: this.$t('Web.Project.PlaceholderProjectName'),
        saveCallback: this.createProject
      });
    }
  }
};
