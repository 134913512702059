<template>
  <SlModal
    :id="id"
    :title="$t('Web.Project.Label.Upload')"
    :loading="isUploading"
    :persistent="isUploading"
    @on-enter="handleUploadProject"
    @hidden="onModalHide"
  >
    <div class="modal-content">
      <ValidationObserver ref="uploadObserver">
        <SlValidate
          v-slot="{ invalid, validate }"
          vid="file"
          :rules="uploadConfig.validate"
        >
          <SlDropzone
            ref="dropzone"
            :accepted="uploadConfig.accepted"
            :validator="validate"
            :is-invalid="invalid"
            @files-updated="handleFilesUpdated"
          >
            <template #accepted>
              {{ $t(uploadConfig.label) }}
            </template>
          </SlDropzone>
        </SlValidate>
      </ValidationObserver>
      <transition name="fade">
        <SlAlert
          v-if="errorData"
          type="error"
          :text="errorData.message"
        >
          <template #link>
            <SlLink
              v-if="errorData.link"
              @click="handleOpenChat"
            >
              {{ $t('Web.Modals.Error.ContactSupport') }}
            </SlLink>
          </template>
        </SlAlert>
      </transition>
    </div>

    <template #footer>
      <SlModalFooter between>
        <div class="sl-modal__footer-group">
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCancel"
          >
            {{ $t('Common.Cancel') }}
          </SlButton>
          <SlButton
            variant="secondary"
            color="primary"
            :disabled="!newFile"
            @click="handleUploadProject"
          >
            {{ $t('Web.Project.Upload') }}
          </SlButton>
          <SlButton
            :disabled="!newFile"
            @click="handleUploadAndRunProject"
          >
            {{ $t('Web.Project.UploadRun') }}
          </SlButton>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import { fileFormats, uploadConfigByFormat } from '@/config/utils/fileUpload.config';
import modalsId from '@/config/shared/modalsId.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { mapActions } from 'vuex';
import { routeNames } from '@/config/router/router.config';
import { projectRedirect } from '@/helpers/router';

export default {
  name: 'UploadProjectModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.UPLOAD_PROJECT_FILE,
      isUploading: false,
      newFile: null,
      errorData: null
    };
  },
  computed: {
    uploadConfig() {
      return uploadConfigByFormat[fileFormats.gsl];
    }
  },
  methods: {
    ...mapActions({
      handleProjectStart: 'manageProjects/handleProjectStart',
      uploadProject: 'manageProjects/uploadProject'
    }),
    onModalHide() {
      this.isUploading = false;
      this.newFile = false;
      this.errorData = null;
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    async handleFilesUpdated(files) {
      this.errorData = null;
      this.newFile = files[0];
    },
    async uploadProjectHandler() {
      const valid = await this.$refs.uploadObserver.validate();
      const dropzoneValid = this.$refs.dropzone.validate();

      if (!valid || !dropzoneValid) {
        return;
      }
      this.errorData = null;

      try {
        this.isUploading = true;

        const response = await this.uploadProject(this.newFile);

        this.hideModal(this.id);

        return response;
      } catch (error) {
        const isInaccessibleTenant = error?.code === slErrorCodes.INACCESSIBLE_PROJECT_TENANT;

        this.errorData = {
          message: isInaccessibleTenant
            ? this.$t('Web.Project.InaccessibleFileAlert') + '\n' + this.$t('Web.FileUpload.UploadAnotherFileOr', { 1: '' })
            : error?.message || this.$t('Web.FileUpload.TryAgain'),
          link: isInaccessibleTenant
        };

        // super-kostyl to avoid sl-error-boundary flow
        throw '';
      } finally {
        this.isUploading = false;
      }
    },
    async handleUploadProject() {
      await this.uploadProjectHandler();

      this.postUploadRedirect();
    },
    async handleUploadAndRunProject() {
      const id = await this.uploadProjectHandler();

      this.handleProjectStart({ id });

      this.postUploadRedirect();
    },
    postUploadRedirect() {
      if (this.$sl_routeName === routeNames.PROJECT_LIST) {
        return;
      }

      projectRedirect({
        name: routeNames.PROJECT_LIST
      });
    },
    handleOpenChat() {
      this.showModal(modalsId.REPORT_ISSUE);
    }
  }
};
</script>
