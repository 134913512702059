<template>
  <SystemPageWrapper>
    <h1 class="system-page__title heading-1-sb">
      {{ $t('Web.GetStarted.WithStreamline') }}
    </h1>
    <div class="system-page__links">
      <SlLinkCard
        v-if="$sl_isUserAdmin"
        :title="$t('Web.GetStarted.CreateProjectTitle')"
        :description="$t('Web.GetStarted.CreateProjectDescr')"
        @click="handleCreateProject('getStartedPage')"
      >
        <template #icon>
          <icon
            data="@icons/rocket.svg"
            class="size-64 fill-off"
          />
        </template>
      </SlLinkCard>
      <SlLinkCard
        v-if="!isRestrictedFromUploadingProject"
        :title="$t('Web.GetStarted.UploadTitle')"
        :description="$t('Web.GetStarted.UploadDescr')"
        @click="showUploadModal"
      >
        <template #icon>
          <icon
            data="@icons/style_upload.svg"
            class="size-64 fill-off"
          />
        </template>
      </SlLinkCard>
      <SlLinkCard
        :title="$t('Web.GetStarted.ExamplesTitle')"
        :description="$t('Web.GetStarted.ExamplesDescr')"
        :to="{
          name: routeNames.EXAMPLES
        }"
      >
        <template #icon>
          <icon
            data="@icons/files.svg"
            class="size-64 fill-off"
          />
        </template>
      </SlLinkCard>
    </div>
    <div class="system-page__link">
      <SlLink
        color="primary"
        @click.prevent="login"
      >
        {{ $t('Web.GetStarted.SwitchAcc') }}
      </SlLink>
    </div>

    <UploadProjectModal />
  </SystemPageWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ContextHolder } from '@frontegg/vue';
import UploadProjectModal from '@/components/Modals/Project/UploadProjectModal.vue';
import { routeNames } from '@/config/router/router.config';
import { modal } from '@/mixins/modal';
import { project } from '@/mixins/project';
import modalsId from '@/config/shared/modalsId.config';
import { fileFormats } from '@/config/utils/fileUpload.config';

export default {
  name: 'GetStarted',
  components: {
    UploadProjectModal
  },
  mixins: [modal, project],
  data() {
    return {
      modalsId,
      fileFormats,
      routeNames
    };
  },
  computed: {
    ...mapGetters({
      isFreePlan: 'account/isFreePlan'
    }),
    isRestrictedFromUploadingProject() {
      return !this.$sl_isUserAdmin || this.isFreePlan;
    }
  },
  methods: {
    ...mapActions({
      createProject: 'manageProjects/createProject',
      handleProjectStart: 'manageProjects/handleProjectStart'
    }),
    login() {
      const baseUrl = ContextHolder.getContext().baseUrl;

      window.location.replace(`${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`);
    },
    showUploadModal() {
      this.showModal(modalsId.UPLOAD_PROJECT_FILE);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/shared/page/system-page/get-started";
</style>
